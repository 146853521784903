import React from "react"
import {graphql} from "gatsby"
import parse from "html-react-parser"

import Layout from "../components/layout"
import {Faq} from "src/interfaces/publication"
import {SEO} from "../components/seo"

interface PageQuery {
  data: {
    datoCmsPublication: {
      pgsLabel: string
    }
  }
  pageContext: {
    faq: Faq
    id: string
    title: string
  }
}

interface PageContext {
  pageContext: {
    faq: Faq
    id: string
    title: string
  }
}

const FaqTemplate = ({data, pageContext}: PageQuery) => {
  const {pgsLabel} = data.datoCmsPublication
  const faq = pageContext.faq
  let headingSize = ""
  if (faq.question.length > 80) {
    headingSize = "medium"
  } else if (faq.question.length > 250) {
    headingSize = "small"
  }

  return (
    <Layout currentLocation={faq.link}>
      <div className="question">
        <h1 className={headingSize}>{parse(faq.question)}</h1>
        {parse(faq.answer)}
        <p>
          <br />
          <a
            class="silent"
            href={`/publicaties/${pgsLabel.replace(" ", "").toLowerCase()}`}
          >
            <img src="/images/publications/back-arrow.gif" alt="back" />
            Terug naar Vraag en Antwoord {pgsLabel}
          </a>
        </p>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query ($id: String) {
    datoCmsPublication(id: {eq: $id}) {
      pgsLabel
    }
  }
`

export default FaqTemplate

export const Head = ({pageContext}: PageContext) => {
  return <SEO title={pageContext.faq.question} />
}
